
import { Options, Vue } from "vue-class-component";
import Main from "@/components/Main.vue"; // @ is an alias to /src

@Options({
  components: {
    Main,
  },
})
export default class Home extends Vue {
  data(): { pageTitle: string | symbol | null | undefined } {
    return { pageTitle: this.$router.currentRoute.value.name };
  }
}
