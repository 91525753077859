
import { TimingResult, Definition } from "@/types/index";
import { fetchData } from "@/utils/index";
import { defineComponent } from "vue";
import { Pages } from "@/router/pages";

const { HomePage, Frameworks, Metrics, Comparison, Timings } = Pages;

const Component = defineComponent({
  name: "App",
  data() {
    return {
      timingResults: [] as TimingResult[],
      metrics: {} as Record<string, Definition>,
      frameworks: {} as Record<string, Definition>,
      timings: {} as Record<string, Definition>,
      links: [
        { name: HomePage.name, url: HomePage.path, exact: true },
        { name: Comparison.name, url: Comparison.path },
        { name: Frameworks.name, url: Frameworks.path },
        { name: Metrics.name, url: Metrics.path },
        { name: Timings.name, url: Timings.path },
      ],
    };
  },

  async mounted() {
    const trTask = fetchData<TimingResult[]>(`/trace-results.no-throttle.json`);
    const mrTask = fetchData<Record<string, Definition>>(
      "/metric-definitions.json"
    );
    const frTask = fetchData<Record<string, Definition>>(
      "/framework-definitions.json"
    );
    const tTask = fetchData<Record<string, Definition>>(
      "/timing-definitions.json"
    );
    this.timingResults = (await trTask) as TimingResult[];
    this.metrics = (await mrTask) as Record<string, Definition>;
    this.frameworks = (await frTask) as Record<string, Definition>;
    this.timings = (await tTask) as Record<string, Definition>;
  },
});
export default Component;
